import type { ButtonProps } from "@opensea/ui-kit"
import { classNames } from "@opensea/ui-kit"
import React, { forwardRef } from "react"
import { Button } from "@/design-system/Button"
import type { TooltipProps } from "@/design-system/Tooltip"
import { TextCopier } from "./TextCopier.react"

type TextCopierButtonProps = {
  children?: React.ReactNode
  className?: string
  label?: string
  text: string
  truncationWidth?: string
  placement?: TooltipProps["placement"]
  onCopy?: () => unknown
} & Pick<ButtonProps, "variant">

export const TextCopierButton = forwardRef<
  HTMLButtonElement,
  TextCopierButtonProps
>(function TextCopierButton(
  {
    children,
    text,
    label,
    className,
    truncationWidth,
    placement = "top",
    onCopy,
    variant,
  },
  ref,
) {
  return (
    <TextCopier label={label} placement={placement} text={text}>
      {copy => (
        <Button
          className={classNames(
            {
              "overflow-hidden text-ellipsis": truncationWidth !== undefined,
            },
            className,
          )}
          onClick={() => {
            copy()
            onCopy?.()
          }}
          ref={ref}
          style={{ width: truncationWidth }}
          variant={variant}
        >
          {children ?? text}
        </Button>
      )}
    </TextCopier>
  )
})
