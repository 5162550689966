import { classNames } from "@opensea/ui-kit"
import { Check } from "@opensea/ui-kit/icons"
import React, { useEffect, useState } from "react"
import type { RenderItem } from "@/design-system/Dropdown"
import { Overflow } from "@/design-system/Overflow"
import { Select } from "@/design-system/Select"
import type { ChainOption } from "@/hooks/useChains/useChainOptions"
import { useChainOptions } from "@/hooks/useChains/useChainOptions"
import { getEquallySizedChainLogo } from "@/lib/helpers/chainUtils"
import { useConnectedChain } from "@/providers/Wallet/selectors"
import { useWalletActions } from "@/providers/Wallet/useWalletActions"

export function ChainSwitcher() {
  const connectedChain = useConnectedChain() ?? "ETHEREUM"

  const chainOptions = useChainOptions()

  const [activeChainOption, setActiveChainOption] = useState(() =>
    chainOptions.find(option => option.value === connectedChain),
  )

  useEffect(() => {
    setActiveChainOption(
      chainOptions.find(option => option.value === connectedChain),
    )
  }, [chainOptions, connectedChain])

  const { switchChain } = useWalletActions()
  const onSelectChain = async (option: ChainOption | undefined) => {
    if (!option || option === activeChainOption) {
      return
    }

    await switchChain(option.value)
  }

  const ChainLogo = getEquallySizedChainLogo(connectedChain)

  const renderOption: RenderItem<ChainOption> = props => {
    const { Item, item } = props
    const isActive = item.value === connectedChain

    const OptionChainLogo = getEquallySizedChainLogo(item.value)

    return (
      <Item
        interactive
        key={item.value}
        onClick={isActive ? undefined : () => onSelectChain(item)}
      >
        <Overflow className="flex w-full items-center justify-between">
          <div
            className={classNames("flex items-center gap-x-3", {
              "text-primary": isActive,
              "text-secondary": !isActive,
            })}
          >
            {OptionChainLogo ? (
              <div className="mr-2 inline-block align-sub">
                <OptionChainLogo />
              </div>
            ) : null}
            <p className="text-md font-semibold">{item.label}</p>
          </div>
        </Overflow>
        {isActive ? <Check /> : null}
      </Item>
    )
  }

  return (
    // Wrapped in div to limit width. `Select` currently renders with
    // classname `w-full` that is not easily overridable.
    <div>
      <Select
        className="w-auto bg-transparent"
        maxHeight="460px"
        onSelect={onSelectChain}
        options={chainOptions}
        overrides={{
          ContentLabelTitle: {
            props: {
              children: <></>,
            },
          },
        }}
        renderItem={renderOption}
        startEnhancer={<>{ChainLogo ? <ChainLogo /> : null}</>}
        value={activeChainOption?.value}
        variant="item"
      />
    </div>
  )
}
