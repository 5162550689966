import { useEffect, useCallback } from "react"
import { useToggle } from "react-use"

type UseAutoResetToggleOptions = {
  delay?: number
}

export const useAutoResetToggle = ({
  delay = 1000,
}: UseAutoResetToggleOptions = {}) => {
  const [isToggled, toggle] = useToggle(false)

  useEffect(() => {
    const timeout = isToggled
      ? window.setTimeout(() => {
          toggle(false)
        }, delay)
      : undefined

    return () => {
      clearTimeout(timeout)
    }
  }, [delay, isToggled, toggle])

  const onToggleOn = useCallback(() => {
    toggle(true)
  }, [toggle])

  return [isToggled, onToggleOn] as const
}
