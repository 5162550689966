import { useTranslate } from "@opensea/next-translate"
import React, { useCallback } from "react"
import { useCopyToClipboard } from "react-use"
import type { TooltipProps } from "@/design-system/Tooltip"
import { Tooltip } from "@/design-system/Tooltip"
import { useAutoResetToggle } from "@/hooks/useAutoResetToggle"

type TextCopierProps = {
  label?: string
  placement?: TooltipProps["placement"]
  text: string
  children: (copy: () => void) => JSX.Element
}

export function TextCopier({
  children,
  placement = "top",
  label,
  text,
}: TextCopierProps) {
  const t = useTranslate("common")
  const [isCopied, onCopied] = useAutoResetToggle()
  const [_, copyToClipboard] = useCopyToClipboard()

  const displayMessage = isCopied
    ? t("textCopier.textCopied", "Copied!")
    : label ?? t("textCopier.copy", "Copy")

  const copy = useCallback(() => {
    copyToClipboard(text)
    onCopied()
  }, [onCopied, copyToClipboard, text])

  return (
    <Tooltip
      content={displayMessage}
      hideOnClick={false}
      placement={placement}
      variant="card"
    >
      {children(copy)}
    </Tooltip>
  )
}
