import React, { useMemo } from "react"
import { STRING_SEPARATOR } from "../constants"
import {
  TrackingContextProvider,
  useTrackingContext,
} from "../TrackingContext.react"
import type { PageName } from "./PageTrackingContext/types"

type PageNameSource = PageName

type ComponentSource = "WalletHeader"

/**
 * Pages and Components are proper case. Tabs are lower case.
 */
type SourceName = PageNameSource | ComponentSource

type SourceContext = {
  final: SourceName | undefined
  sources: string
}

export type SourceTrackingContext = {
  Source?: SourceContext
}

type SourceTrackingContextProviderProps = {
  source: SourceName
  children: React.ReactNode
}

export function SourceTrackingContextProvider({
  source,
  children,
}: SourceTrackingContextProviderProps) {
  const { getMergedTrackingContext: getCurrentTrackingContext } =
    useTrackingContext()

  const currentTrackingContext = getCurrentTrackingContext()
  const currentSources = currentTrackingContext.Source?.sources
  const pageName = currentTrackingContext.Page?.name

  const properties: SourceContext = useMemo(() => {
    if (currentSources === undefined) {
      const sources: SourceName[] = []
      if (pageName !== undefined) {
        sources.push(pageName)
      }
      sources.push(source)
      return {
        final: source,
        sources: sources.join(STRING_SEPARATOR),
      }
    }

    return {
      final: source,
      sources: [currentSources, source].join(STRING_SEPARATOR),
    }
  }, [currentSources, pageName, source])

  return (
    <TrackingContextProvider name="Source" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
