import type { Dispatch, SetStateAction } from "react"
import { useCallback, useState } from "react"

export type UseIsOpenReturn = {
  isOpen: boolean
  open: () => void
  close: () => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
  toggle: () => void
}

export const useIsOpen = (initiallyOpen = false): UseIsOpenReturn => {
  const [isOpen, setIsOpen] = useState(initiallyOpen)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])
  const close = useCallback(() => {
    setIsOpen(false)
  }, [])
  const toggle = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  return { isOpen, open, close, setIsOpen, toggle }
}
