import { classNames } from "@opensea/ui-kit"
import type { ListProps } from "@opensea/ui-kit/client"
import { List } from "@opensea/ui-kit/client"
import type { ComponentProps } from "react"
import React, { forwardRef } from "react"

function DropdownListHeader(props: ComponentProps<typeof List.Header>) {
  return <List.Header {...props} className="mx-2 px-6" />
}

const DropdownListBase = forwardRef<HTMLUListElement, ListProps>(
  function DropdownList(props: ListProps, ref) {
    return (
      <List
        {...props}
        className={classNames("relative p-2", props.className)}
        ref={ref}
      />
    )
  },
)

export function DropdownDivider(props: ComponentProps<"li">) {
  return (
    <li
      {...props}
      aria-hidden
      className="-mx-2 my-2 h-px w-[calc(100%+16px)] bg-component-gray-1"
    />
  )
}

export const DropdownList = Object.assign(DropdownListBase, {
  Header: DropdownListHeader,
  Group: List.Group,
})

export const DropdownItem = Object.assign(List.Item, {
  Content: List.Item.Content,
  Title: List.Item.Title,
  Action: List.Item.Action,
  Side: List.Item.Side,
  Description: List.Item.Description,
  Value: List.Item.Value,
  dataId: "Item",
})
