import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { WalletBalanceContext } from "../context"
import type { WalletBalance } from "../context"

export function useAllWalletBalances(chain?: ChainIdentifier): {
  balances: WalletBalance[]
  ready: boolean
} {
  const balances = useContextSelector(
    WalletBalanceContext,
    (context): WalletBalance[] => {
      const allBalances = Object.values(context.balances) as WalletBalance[]
      if (chain === undefined) {
        return allBalances
      }
      return allBalances.filter(b => b.currency.chain === chain)
    },
  )

  const ready = useContextSelector(WalletBalanceContext, context => {
    return context.readyChains[chain ?? "all"] ?? false
  })

  const subscribe = useContextSelector(WalletBalanceContext, context => {
    return context.subscribe
  })
  useEffect(() => {
    const unsubscribe = subscribe(chain)
    return unsubscribe
  }, [chain, subscribe])

  return { balances, ready }
}
