import { classNames } from "@opensea/ui-kit"
import {
  Input as DsInput,
  type InputProps as DsInputProps,
} from "@opensea/ui-kit/client"
import React, { forwardRef } from "react"

export type InputProps = DsInputProps

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { children, className, ...rest }: InputProps,
  ref,
) {
  return (
    <DsInput
      className={classNames("h-12 rounded-xl p-3", className)}
      ref={ref}
      {...rest}
    >
      {children}
    </DsInput>
  )
})
