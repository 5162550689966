import { Skeleton } from "@opensea/ui-kit"
import React from "react"
import { TextCopierButton } from "@/components/common/TextCopierButton.react"
import { getWalletLogo } from "@/constants/wallet"
import { truncateAddress } from "@/lib/helpers/address/format"
import {
  useConnectedAddress,
  useConnectedWallet,
} from "@/providers/Wallet/selectors"

export function WalletAddress() {
  const wallet = useConnectedWallet()
  const walletAddress = useConnectedAddress()

  const walletLogo = wallet ? getWalletLogo(wallet) : undefined

  return (
    <div className="flex items-center">
      {walletAddress ? (
        <TextCopierButton
          className="bg-transparent p-3 font-normal hover:bg-component-gray-2"
          text={walletAddress}
          variant="secondary"
        >
          {walletLogo ? (
            <>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img className="h-5" src={walletLogo} />
            </>
          ) : null}
          {truncateAddress(walletAddress)}
        </TextCopierButton>
      ) : (
        <Skeleton>
          <Skeleton.Row className="p-3">
            <Skeleton.Line className="h-6 w-[100px]" />
          </Skeleton.Row>
        </Skeleton>
      )}
    </div>
  )
}
