import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"
import { WalletBalanceContext } from "../context"
import { getCurrencyId, type CurrencyInput } from "../currency"
import type { WalletBalance } from "../context"

export function useWalletBalance(currency: CurrencyInput): {
  balance: WalletBalance
  ready: boolean
} {
  const balance = useContextSelector(WalletBalanceContext, context => {
    return context.balances[getCurrencyId(currency)]
  }) ?? {
    currency: {
      ...currency,
      usdPrice: undefined,
      imageUrl: undefined,
      name: undefined,
    },
    quantity: "0",
  }
  const ready = useContextSelector(WalletBalanceContext, context => {
    return (
      Boolean(context.readyChains[currency.chain]) ||
      Boolean(context.readyCurrencies[getCurrencyId(currency)])
    )
  })

  const subscribe = useContextSelector(WalletBalanceContext, context => {
    return context.subscribe
  })
  useEffect(() => {
    const unsubscribe = subscribe(currency.chain, currency.symbol)
    return unsubscribe
  }, [currency.chain, currency.symbol, subscribe])

  return { balance, ready }
}
