import { isBoolean } from "lodash"
import React, { forwardRef, useCallback, useRef } from "react"
import type {
  TooltipProps,
  TooltipInstance,
  TooltipPlacement,
} from "../Tooltip"
import { Tooltip, TOOLTIP_PLACEMENT } from "../Tooltip"

export const POPOVER_PLACEMENT = TOOLTIP_PLACEMENT

export type PopoverPlacement = TooltipPlacement

export type PopoverContentProps = {
  close: () => void
}

export type PopoverProps = Pick<
  TooltipProps,
  | "animation"
  | "appendTo"
  | "children"
  | "contentPadding"
  | "delay"
  | "placement"
  | "arrow"
  | "className"
  | "disabled"
  | "offset"
  | "maxWidth"
  | "hideOnClick"
  | "interactive"
  | "trigger"
  | "visible"
  | "variant"
  | "popperOptions"
  | "lazy"
  | "onTrigger"
  | "onUntrigger"
  | "onHide"
  | "onHidden"
  | "getReferenceClientRect"
  | "zIndex"
  | "hideOnScroll"
  | "matchReferenceWidth"
  | "onShown"
> & {
  content: (props: PopoverContentProps) => React.ReactNode
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  function Popover(
    {
      content,
      hideOnClick = true,
      interactive = true,
      trigger,
      visible,
      ...rest
    },
    ref,
  ) {
    const tippyRef = useRef<TooltipInstance>()
    const close = useCallback(() => tippyRef.current?.hide(), [tippyRef])

    // Tippy doesn't clear out undefined props so an error gets thrown if either `trigger` or `visible` is not defined
    // https://github.com/atomiks/tippyjs-react/issues/304
    const controlProps = !isBoolean(visible)
      ? trigger
        ? { trigger, hideOnClick }
        : { hideOnClick }
      : { visible }

    return (
      <Tooltip
        {...rest}
        {...controlProps}
        aria={{ expanded: "auto" }}
        content={() => content({ close })}
        interactive={interactive}
        noContentPadding
        onMount={instance => {
          tippyRef.current = instance
        }}
        ref={ref}
        variant="card"
      />
    )
  },
)
