import React, { useCallback, useEffect, useRef } from "react"
import { captureException } from "@sentry/nextjs"
import { useLocalStorage } from "react-use"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { bn } from "@/lib/helpers/numberUtils"
import { useConnectedAddress } from "../Wallet/selectors"
import { WalletBalanceContext, type WalletBalance } from "./context"
import type { CurrencyId } from "./currency"
import { getCurrencyInputFromId, getCurrencyId } from "./currency"
import { useFetchAllWalletBalances } from "./fetch-hooks/useFetchAllWalletBalances"
import { usePoll } from "./fetch-hooks/usePoll"
import { useFetchWalletBalances } from "./fetch-hooks/useFetchWalletBalances"

const ALL_WALLET_BALANCES_POLL_INTERVAL = 15_000 // 15 seconds
const INDIVIDUAL_BALANCES_POLL_INTERVAL = 10_000 // 10 seconds

type WalletBalanceProviderProps = {
  children: React.ReactNode
}

export function WalletBalanceProvider({
  children,
}: WalletBalanceProviderProps) {
  const connectedAddress = useConnectedAddress()

  const [balances, setBalances] = useLocalStorage<
    Record<CurrencyId, WalletBalance | undefined>
  >("WalletBalanceProvider-balances", {})
  const balancesRef = useRef(balances)
  balancesRef.current = balances

  // Handle subscriptions to all balances (all currencies on a specific chain or on all chains)
  const subscribedChainsRef = useRef<
    Partial<Record<ChainIdentifier | "all", number>>
  >({})
  const [readyChains, setReadyChains] = useLocalStorage<
    Partial<Record<ChainIdentifier | "all", boolean>>
  >("WalletBalanceProvider-readyChains", {})
  const fetchAllWalletBalances = useFetchAllWalletBalances()
  const fetchChainBalances = useCallback(async () => {
    if (!connectedAddress) {
      return
    }
    await Promise.all(
      Object.entries(subscribedChainsRef.current).map(
        async ([chain, subCount]): Promise<void> => {
          if (subCount <= 0) {
            return
          }
          const resolvedChain =
            chain === "all" ? undefined : (chain as ChainIdentifier)
          try {
            const newBalances = await fetchAllWalletBalances(
              connectedAddress,
              resolvedChain,
            )
            // Use balanceRef instead of a callback since useLocalStorage setter callback
            // does not receive the latest state
            setBalances({
              ...Object.fromEntries(
                Object.entries(balancesRef.current ?? {}).filter(([_, b]) => {
                  const isOnFetchedChain = b?.currency.chain === resolvedChain
                  const hasBalance = b && !bn(b.quantity).isZero()
                  // Keep balances on other chains and balances with zero quantity
                  return !isOnFetchedChain || !hasBalance
                }),
              ),
              ...Object.fromEntries(
                newBalances.map(b => [getCurrencyId(b.currency), b]),
              ),
            })
            setReadyChains(chains => ({
              ...chains,
              [chain]: true,
            }))
          } catch (error) {
            const errorId = captureException(error, {
              extra: {
                connectedAddress,
                chain,
                query: "useFetchAllWalletBalancesQuery",
              },
            })
            console.error(
              `Error fetching balances for chain ${chain}: ${errorId}`,
              error,
            )
          }
        },
      ),
    )
  }, [connectedAddress, fetchAllWalletBalances, setBalances, setReadyChains])
  usePoll(fetchChainBalances, ALL_WALLET_BALANCES_POLL_INTERVAL)

  // Handle individual currency subscriptions
  const [readyCurrencies, setReadyCurrencies] = useLocalStorage<
    Partial<Record<CurrencyId, boolean>>
  >("WalletBalance-readyCurrencies", {})
  const currencySubscriptionsRef = useRef<Map<CurrencyId, number>>(new Map())
  const { fetch: fetchIndividualWalletBalances } = useFetchWalletBalances()
  const fetchCurrencyBalances = useCallback(async () => {
    if (!connectedAddress) {
      return
    }
    if (
      subscribedChainsRef.current.all &&
      subscribedChainsRef.current.all > 0
    ) {
      // We are already fetching all balances, no need to fetch individual ones
      return
    }
    const subscribedCurrencies = Array.from(
      currencySubscriptionsRef.current.entries(),
    ).flatMap(([currencyId, subCount]) => {
      if (subCount <= 0) {
        return []
      }
      const currency = getCurrencyInputFromId(currencyId)
      return currency
    })
    // Filter out currencies that are already being fetched as part of a chain subscription
    const filteredCurrencies = subscribedCurrencies.filter(c => {
      const chainSubCount = subscribedChainsRef.current[c.chain]
      return !chainSubCount || chainSubCount <= 0
    })
    try {
      const newBalances = await fetchIndividualWalletBalances(
        connectedAddress,
        filteredCurrencies,
      )
      // Use balanceRef instead of a callback since useLocalStorage setter callback
      // does not receive the latest state
      setBalances({
        ...balancesRef.current,
        ...Object.fromEntries(
          newBalances.map(b => [getCurrencyId(b.currency), b]),
        ),
      })
      setReadyCurrencies(currencies => ({
        ...currencies,
        ...Object.fromEntries(
          newBalances.map(b => [getCurrencyId(b.currency), true]),
        ),
      }))
    } catch (error) {
      const errorId = captureException(error, {
        extra: {
          connectedAddress,
          currencies: filteredCurrencies,
          query: "useFetchWalletBalancesQuery",
        },
      })
      console.error(`Error fetching balances for currencies: ${errorId}`, error)
    }
  }, [
    connectedAddress,
    fetchIndividualWalletBalances,
    setBalances,
    setReadyCurrencies,
  ])
  usePoll(fetchCurrencyBalances, INDIVIDUAL_BALANCES_POLL_INTERVAL)

  const subscribe = useCallback(
    (chain?: ChainIdentifier, symbol?: string) => {
      const resolvedChain: ChainIdentifier | "all" = chain ? chain : "all"

      if (chain === undefined || symbol === undefined) {
        const oldChainSubCount = subscribedChainsRef.current[resolvedChain]
        subscribedChainsRef.current[resolvedChain] = (oldChainSubCount ?? 0) + 1
        void fetchChainBalances()
        return () => {
          const chainSubCount = subscribedChainsRef.current[resolvedChain]
          if (chainSubCount) {
            subscribedChainsRef.current[resolvedChain] = chainSubCount - 1
          }
        }
      }

      const currencyId = getCurrencyId({ symbol, chain })
      const oldSubCount = currencySubscriptionsRef.current.get(currencyId)
      currencySubscriptionsRef.current.set(
        currencyId,
        oldSubCount ? oldSubCount + 1 : 1,
      )
      void fetchCurrencyBalances()
      return () => {
        const subCount = currencySubscriptionsRef.current.get(currencyId)
        if (subCount) {
          currencySubscriptionsRef.current.set(
            currencyId,
            Math.min(subCount - 1, 0),
          )
        }
      }
    },
    [fetchChainBalances, fetchCurrencyBalances],
  )

  const refresh = useCallback(async () => {
    if (!connectedAddress) {
      return
    }
    await Promise.all([fetchChainBalances(), fetchCurrencyBalances()])
  }, [connectedAddress, fetchChainBalances, fetchCurrencyBalances])

  // Reset balance when connected address changes
  useEffect(() => {
    setBalances({})
    setReadyChains({})
    setReadyCurrencies({})
    void refresh()
  }, [
    connectedAddress,
    refresh,
    setBalances,
    setReadyChains,
    setReadyCurrencies,
  ])

  return (
    <WalletBalanceContext.Provider
      value={{
        subscribe,
        refresh,
        balances: balances ?? {},
        readyChains: readyChains ?? {},
        readyCurrencies: readyCurrencies ?? {},
      }}
    >
      {children}
    </WalletBalanceContext.Provider>
  )
}
