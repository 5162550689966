import { useMemo } from "react"
import type { BigNumber } from "@/lib/helpers/numberUtils"
import { bn } from "@/lib/helpers/numberUtils"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useAllWalletBalances } from "./useWalletBalances"

export function useTotalWalletValueUsd(chain?: ChainIdentifier): {
  value: BigNumber
  ready: boolean
} {
  const { balances, ready } = useAllWalletBalances(chain)

  const value = useMemo(() => {
    if (!ready) {
      return bn(0)
    }

    return balances.reduce((acc, b) => {
      if (!b.currency.usdPrice) {
        return acc
      }
      return acc.plus(bn(b.quantity).times(b.currency.usdPrice))
    }, bn(0))
  }, [balances, ready])
  return { value, ready }
}
