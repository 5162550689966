import type { TrackingContextType } from "./types"

export const STRING_SEPARATOR = ", "

// Constants used in storybooks and tests

type SourceContext = TrackingContextType["Source"]
type PageContext = TrackingContextType["Page"]

export const SOURCE_CONTEXT: SourceContext = {
  final: "WalletHeader",
  sources: "AssetsPage,WalletHeader",
}

// Default values for properties from document/window that are not available during SSR
export const PAGE_CONTEXT_SERVER_DEFAULTS = {
  title: "",
  url: "",
} as const

export const PAGE_CONTEXT: PageContext = {
  name: "Assets",
  path: "/",
  queryString: "",
  ...PAGE_CONTEXT_SERVER_DEFAULTS,
}
