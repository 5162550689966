import { useEffect, useState } from "react"

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const mediaQuery = window.matchMedia("(any-pointer:coarse)")
    const listener = () => {
      setIsMobile(mediaQuery.matches)
    }
    mediaQuery.addEventListener("change", listener)
    listener()
    return () => {
      mediaQuery.removeEventListener("change", listener)
    }
  }, [])

  return isMobile
}
