import { classNames } from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import React, { forwardRef, useRef, useState } from "react"
import type { TooltipProps } from "@/design-system/Tooltip"
import { Tooltip } from "@/design-system/Tooltip"
import { useMountEffect } from "@/hooks/useMountEffect"

const getIsOverflowed = (element: HTMLDivElement | null) => {
  if (!element) {
    return false
  }

  const { clientHeight, clientWidth, scrollHeight, scrollWidth } = element

  return scrollHeight > clientHeight || scrollWidth > clientWidth
}

export type OverflowProps = {
  children: React.ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements | React.ComponentType
  lines?: number
  /**
   * Prevents click event from bubbling up when the content is overflowed.
   * Useful for allowing tap to reveal tooltip on mobile when content is in an
   * interactive element.
   */
  preventClickThrough?: boolean
  overrides?: {
    Tooltip?: Partial<TooltipProps>
  }
}

export const Overflow = forwardRef<HTMLDivElement, OverflowProps>(
  function Overflow(
    { children, className, lines, preventClickThrough, overrides = {} },
    ref,
  ) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [overflowed, setOverflowed] = useState(false)

    useMountEffect(() => {
      const isOverflowed = getIsOverflowed(containerRef.current)
      setOverflowed(isOverflowed)
    })

    return (
      <Tooltip
        content={children}
        disabled={!overflowed}
        variant="card"
        {...overrides.Tooltip}
      >
        <div
          className={classNames("w-full truncate", className)}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if (overflowed && preventClickThrough) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
          onMouseOver={() => {
            const isOverflowed = getIsOverflowed(containerRef.current)
            setOverflowed(isOverflowed)
          }}
          ref={useMergedRef(ref, containerRef)}
          style={{
            display: lines ? "-webkit-box" : undefined,
            WebkitLineClamp: lines,
            WebkitBoxOrient: lines ? "vertical" : undefined,
            whiteSpace: lines ? "normal" : undefined,
          }}
          tabIndex={!overflowed ? -1 : 0}
        >
          {children}
        </div>
      </Tooltip>
    )
  },
)
