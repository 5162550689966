/**
 * @generated SignedSource<<f50ab05ab78ff84fea7b75ba7eb988e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "BAOBAB" | "BASE" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "SEI" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type useFetchWalletBalancesQuery$variables = {
  address: string;
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value";
  symbol: string;
};
export type useFetchWalletBalancesQuery$data = {
  readonly wallet: {
    readonly fundsOf: {
      readonly chain: ChainIdentifier;
      readonly image: string | null | undefined;
      readonly name: string | null | undefined;
      readonly quantity: string;
      readonly symbol: string;
      readonly usdPrice: string | null | undefined;
    };
  };
};
export type useFetchWalletBalancesQuery = {
  response: useFetchWalletBalancesQuery$data;
  variables: useFetchWalletBalancesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "symbol"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "symbol"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usdPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFetchWalletBalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFetchWalletBalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "057cae0269d00490ab06951e41870cd4",
    "id": null,
    "metadata": {},
    "name": "useFetchWalletBalancesQuery",
    "operationKind": "query",
    "text": "query useFetchWalletBalancesQuery(\n  $address: AddressScalar!\n  $chain: ChainScalar!\n  $symbol: String!\n) {\n  wallet(address: $address) {\n    fundsOf(chain: $chain, symbol: $symbol) {\n      symbol\n      chain\n      quantity\n      usdPrice\n      image\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19d88b40b4f598c5117eb2c332b0c385";

export default node;
