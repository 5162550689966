import { useIsHydrated } from "@opensea/react-media"
import { clamp } from "lodash"
import React, { Suspense, useRef } from "react"
import { useScroll } from "react-use"
import useMergedRef from "@react-hook/merged-ref"
import { ChainSwitcher } from "@/features/wallet/components/ChainSwitcher"
import { Tabs } from "@/features/wallet/components/Tabs"
import { WalletAddress } from "@/features/wallet/components/WalletAddress"
import { useElementSize } from "@/hooks/useElementSize"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { useVesselActions } from "../Vessel00/useVesselActions"
import { useIsMobile } from "@/hooks/useIsMobile"
import { Close } from "@opensea/ui-kit/icons"
import { FlexEnd } from "@opensea/ui-kit"

export function WalletLayout({
  children,
  setHeaderEl,
  hideHeader,
}: {
  children: React.ReactNode
  setHeaderEl: (el: HTMLDivElement | null) => void
  hideHeader?: boolean
}) {
  const headerRef = useRef<HTMLDivElement>(null)
  const mergedHeaderRef = useMergedRef(headerRef, setHeaderEl)
  const [, headerHeight] = useElementSize(headerRef)
  const isHydrated = useIsHydrated()
  const isMobile = useIsMobile()
  const { hideWallet } = useVesselActions()

  const scrollRef = useRef<HTMLDivElement>(null)
  const { y } = useScroll(scrollRef)
  const scrollPercent = headerHeight > 0 ? y / headerHeight : 0
  const opacity = clamp(1 - scrollPercent, 0.4, 1)
  const scale = clamp(1 - scrollPercent * 0.05, 0, 1)

  return (
    <div className="relative flex h-screen flex-col bg-component-gray-1">
      {!hideHeader && (
        <SourceTrackingContextProvider source="WalletHeader">
          <div className="flex justify-between p-3 ">
            {isHydrated ? (
              <>
                <WalletAddress />
                <FlexEnd>
                  <Suspense>
                    <ChainSwitcher />
                  </Suspense>
                  {isMobile && (
                    <FlexEnd className="items-center pl-6 pr-2">
                      <Close onClick={hideWallet} />
                    </FlexEnd>
                  )}
                </FlexEnd>
              </>
            ) : null}
          </div>
        </SourceTrackingContextProvider>
      )}
      <div
        className="relative flex flex-1 flex-col overflow-auto rounded-t-large"
        ref={scrollRef}
      >
        <div
          className="sticky top-0 z-0 h-0 shrink-0 overflow-hidden opacity-0 transition-[opacity,height] duration-200 ease-out"
          style={{
            height: headerHeight,
            opacity,
            transform: `scale(${scale})`,
          }}
        >
          <div ref={mergedHeaderRef} />
        </div>
        <div className="relative z-10 flex flex-1 flex-col rounded-t-large">
          <div className="flex-1 rounded-t-large bg-elevation-2 shadow-elevation-1-subtle">
            {children}
          </div>
        </div>
      </div>
      <Tabs />
    </div>
  )
}
