import useResizeObserver from "@react-hook/resize-observer"
import type { RefObject } from "react"
import { useState } from "react"
import { useIsomorphicLayoutEffect } from "react-use"

export const useElementSize = (ref?: RefObject<HTMLElement> | null) => {
  const [size, setSize] = useState([0, 0] as [number, number])

  useIsomorphicLayoutEffect(() => {
    if (ref?.current) {
      const box = ref.current.getBoundingClientRect()

      setSize([box.width, box.height])
    }
  }, [ref?.current])

  // Where the magic happens
  useResizeObserver(ref?.current ?? null, entry => {
    setSize([entry.contentRect.width, entry.contentRect.height])
  })
  return size
}
