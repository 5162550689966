import { GlobalModal } from "./GlobalModal"
import { WalletBalanceProvider } from "./WalletBalanceProvider"
import { WalletLayoutProvider } from "./WalletLayout"

type UIProvidersProps = {
  children: React.ReactNode
  layoutOptions?: {
    disabled?: boolean
    hideHeader?: boolean
  }
}

export function UIProviders({
  children,
  layoutOptions = {},
}: UIProvidersProps) {
  return (
    <WalletBalanceProvider>
      <WalletLayoutProvider {...layoutOptions}>
        <GlobalModal />
        {children}
      </WalletLayoutProvider>
    </WalletBalanceProvider>
  )
}
