import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import {
  Paid,
  PaidFilled,
  Settings,
  SettingsFilled,
  ViewList,
  ViewListFilled,
  SwapHoriz,
} from "@opensea/ui-kit/icons"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import { useIsHydrated } from "@opensea/ui-kit/client"
import { IS_TESTNET } from "@/constants/testnet"
import { WALLET_NAME } from "@/constants/wallet"
import { useConnectedWallet } from "@/providers/Wallet/selectors"

export function Tabs() {
  const t = useTranslate("common")
  const { pathname } = useRouter()

  const isHydrated = useIsHydrated()
  const isEmbeddedWallet =
    useConnectedWallet() === WALLET_NAME.OpenSeaWallet && isHydrated

  const renderTab = (
    path: string,
    label: React.ReactNode,
    icon: (isActive: boolean) => React.ReactNode,
  ) => {
    const isActive = pathname === path

    return (
      <li
        className="flex h-full flex-1 flex-col border-t border-level-1"
        role="menuitem"
      >
        <Link
          className={classNames(
            "flex flex-1 flex-col items-center justify-center text-xs",
            isActive ? "text-primary" : "text-secondary",
          )}
          href={path}
        >
          <span className="inline-block">{icon(isActive)}</span>
          {label}
        </Link>
      </li>
    )
  }

  return (
    <nav className="bg-elevation-2" role="navigation">
      <ul className="flex h-[86px]" role="menu">
        {renderTab("/", t("tabs.crypto", "Crypto"), isActive =>
          isActive ? <PaidFilled size={28} /> : <Paid size={28} />,
        )}
        {!IS_TESTNET
          ? renderTab("/swap", t("tabs.swaps", "Swap"), () => (
              <SwapHoriz size={28} />
            ))
          : null}
        {renderTab(
          "/transactions",
          t("tabs.transcations", "Transactions"),
          isActive =>
            isActive ? <ViewListFilled size={28} /> : <ViewList size={28} />,
        )}
        {renderTab("/settings", t("tabs.settings", "Settings"), isActive =>
          isActive ? <SettingsFilled size={28} /> : <Settings size={28} />,
        )}
      </ul>
    </nav>
  )
}
