import { useEffect } from "react"
import { useIsWalletVisible } from "@/providers/Wallet/selectors"

export function usePoll(
  pollFn: () => Promise<void> | void,
  interval: number,
): void {
  const visible = useIsWalletVisible()
  useEffect(() => {
    if (visible) {
      let timeout: NodeJS.Timeout
      const poll = async () => {
        await pollFn()
        timeout = setTimeout(poll, interval)
      }
      void poll()
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [interval, pollFn, visible])
}
