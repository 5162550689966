import type { ChainIdentifier } from "@/hooks/useChains/types"

export type CurrencyInput = {
  chain: ChainIdentifier
  symbol: string
}

export type Currency = CurrencyInput & {
  usdPrice: string | undefined
  imageUrl: string | undefined
  name: string | undefined
}

export type CurrencyId = `${ChainIdentifier}-${string}`

export function getCurrencyId(currency: CurrencyInput): CurrencyId {
  return `${currency.chain}-${currency.symbol}`
}

export function getCurrencyInputFromId(currencyId: CurrencyId): CurrencyInput {
  const parts = currencyId.split("-")
  return {
    chain: parts[0] as ChainIdentifier,
    symbol: parts[1],
  }
}

export function currenciesEqual(a: Currency, b: Currency): boolean {
  return a.chain === b.chain && a.symbol === b.symbol
}
