import "tippy.js/animations/shift-away.css"
import { classNames } from "@opensea/ui-kit"
import { noop } from "lodash"
import React from "react"
import type { PopoverContentProps, PopoverProps } from "../Popover"
import { Popover, POPOVER_PLACEMENT } from "../Popover"
import { DropdownDivider, DropdownList, DropdownItem } from "./elements"

export const DROPDOWN_PLACEMENT = POPOVER_PLACEMENT

type CloseDropdown = PopoverContentProps["close"]

export type RenderItemProps<T> = {
  item: T
  close: CloseDropdown
  Item: typeof DropdownItem
  onClick?: () => void
  onBlur?: (event: { relatedTarget: EventTarget | null }) => void
}

export type RenderItem<T> = (props: RenderItemProps<T>) => React.ReactNode

type DropdownBaseProps = Omit<PopoverProps, "content"> &
  Pick<React.CSSProperties, "width" | "minWidth" | "maxHeight"> & {
    /**
     * Using this prop instead of forwarding the ref since forwardRef cannot
     * preserve the generic item type (T) on DropdownItemProps
     */
    popoverRef?: React.Ref<HTMLDivElement>
  }

export type DropdownItemProps<T> = DropdownBaseProps & {
  items: readonly T[]
  renderItem: RenderItem<T>
  content?: undefined
  dropdownRef?: React.RefObject<HTMLDivElement>
}

export type RenderDropdownContentProps = {
  close: CloseDropdown
  Item: typeof DropdownItem
  List: typeof DropdownList
  Divider?: typeof DropdownDivider
}

export type DropdownContentProps = DropdownBaseProps & {
  content: (props: RenderDropdownContentProps) => React.ReactNode
  items?: undefined
  renderItem?: undefined
  dropdownRef?: undefined
}

export type DropdownProps<T> = DropdownItemProps<T> | DropdownContentProps

function DropdownBase<T>({
  appendTo = "parent",
  width,
  children,
  delay,
  placement = "bottom-start",
  disabled,
  className,
  offset,
  maxWidth,
  hideOnClick,
  hideOnScroll,
  trigger = "click",
  visible,
  popperOptions,
  lazy,
  onTrigger,
  onUntrigger,
  onHide,
  onHidden,
  animation,
  getReferenceClientRect,
  zIndex,
  variant,
  matchReferenceWidth,
  popoverRef,
  onShown,
  ...rest
}: DropdownProps<T>) {
  const renderContent = ({ close }: PopoverContentProps) => {
    if (rest.content) {
      return rest.content({
        close,
        Divider: DropdownDivider,
        Item: DropdownItem,
        List: DropdownList,
      })
    }
    const { items, renderItem } = rest
    return (
      <DropdownList style={{ width }}>
        {items.map(item =>
          renderItem({
            close,
            item,
            Item: DropdownItem,
          }),
        )}
      </DropdownList>
    )
  }

  return (
    <Popover
      animation={animation}
      appendTo={appendTo}
      arrow={false}
      className={classNames(
        "max-h-[350] min-w-[220px] overflow-y-auto rounded-large bg-transparent text-primary",
        className,
      )}
      content={renderContent}
      delay={delay}
      disabled={disabled}
      getReferenceClientRect={getReferenceClientRect}
      hideOnClick={hideOnClick}
      hideOnScroll={hideOnScroll}
      lazy={lazy}
      matchReferenceWidth={matchReferenceWidth}
      maxWidth={maxWidth}
      offset={offset}
      onHidden={onHidden || noop}
      onHide={onHide || noop}
      onShown={onShown}
      onTrigger={onTrigger}
      onUntrigger={onUntrigger || noop}
      placement={placement}
      popperOptions={popperOptions}
      ref={popoverRef}
      trigger={trigger}
      variant={variant}
      visible={visible}
      zIndex={zIndex}
    >
      {children}
    </Popover>
  )
}

export const Dropdown = Object.assign(DropdownBase, {
  Item: DropdownItem,
  List: DropdownList,
})
