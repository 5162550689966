import type { ItemProps } from "@opensea/ui-kit"
import {
  classNames,
  Item,
  preventInputZoomStyles,
  twDataIdSelector,
} from "@opensea/ui-kit"
import { CheckCircleFilled } from "@opensea/ui-kit/icons"
import useSize from "@react-hook/size"
import type { ReactNode } from "react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useClickAway, useKeyPressEvent } from "react-use"
import type { ShowMoreIconProps } from "@/components/common/ShowMoreIcon"
import { ShowMoreIcon } from "@/components/common/ShowMoreIcon"
import type { DropdownProps, RenderItem } from "@/design-system/Dropdown"
import { Dropdown } from "@/design-system/Dropdown"
import type { InputProps } from "@/design-system/Input"
import { Input } from "@/design-system/Input"
import { Loader } from "@/design-system/Loader"
import { useIsOpen } from "@/hooks/useIsOpen"
import { isInsideElement, isInsideRef } from "@/lib/helpers/dom"
import { UnreachableCaseError } from "@/lib/helpers/type"

export type SelectOption<Value extends string = string> = {
  label: string
  value: Value
  key?: string
  description?: string
  disabled?: boolean
  startEnhancer?: ReactNode
  date?: Date
}

export type StringSelectOption = Omit<SelectOption, "value"> & {
  value: string
}

export type LoadingConfiguration = {
  avatar?: boolean
  title?: boolean
  description?: boolean
  count?: number
}

export type SelectProps<
  Value extends string = string,
  Option extends SelectOption<Value> = SelectOption<Value>,
> = {
  autoComplete?: string
  disabled?: boolean
  options: readonly Option[]
  /**
   * Options that are supported, but not necessarily displayed, Used for
   * providing labels for options that are not currently displayed.
   *
   * Use options above for options that are displayed.
   */
  optionsSupported?: readonly Option[]
  className?: string
  onSelect: (option: Option | undefined) => unknown
  placeholder?: string
  value?: Value
  renderItem?: RenderItem<Option>
  startEnhancer?: React.ReactNode
  midEnhancer?: React.ReactNode
  endEnhancer?: React.ReactNode
  clearable?: boolean
  searchFilter?: (option: Option, query: string) => boolean
  readOnly?: boolean
  emptyText?: string
  autoFocus?: boolean
  bordered?: boolean
  variant?: "search" | "item"
  name?: string
  id?: string
  isLoading?: boolean | LoadingConfiguration
  onChange?: (value: string) => unknown
  style?: React.CSSProperties
  excludeSelectedOption?: boolean
  maxHeight?: string
  matcher?: (option: Option, value: Value | undefined) => boolean
  onOpenChange?: (open: boolean) => unknown
  hideOnScroll?: boolean
  overrides?: {
    Dropdown?: {
      props: Pick<
        Partial<DropdownProps<Value>>,
        "popperOptions" | "appendTo" | "placement"
      >
    }
    ContentItem?: {
      props: Partial<ItemProps>
    }
    ContentLabel?: {
      props: { className?: string }
    }
    ContentLabelTitle?: {
      props: { className?: string; children?: React.ReactNode }
    }
    Input?: {
      props: Partial<InputProps>
    }
    ShowMoreIcon?: {
      props: Partial<ShowMoreIconProps>
    }
  }
  "aria-label"?: string
  tabIndex?: number
}

export function Select<
  Value extends string,
  Option extends SelectOption<Value>,
>({
  autoComplete,
  disabled,
  className,
  placeholder,
  options,
  optionsSupported,
  onSelect,
  renderItem,
  value,
  startEnhancer,
  endEnhancer,
  clearable = true,
  searchFilter = (option, query) =>
    Boolean(
      option.label.toLowerCase().includes(query) ||
        option.description?.toLowerCase().includes(query),
    ),
  readOnly,
  emptyText,
  autoFocus,
  bordered = true,
  variant = "search",
  name,
  id,
  isLoading = false,
  onChange,
  style,
  excludeSelectedOption = false,
  maxHeight,
  matcher = (option, value) => option.value === value,
  onOpenChange,
  hideOnScroll,
  overrides,
  "aria-label": ariaLabel,
  tabIndex,
}: SelectProps<Value, Option>) {
  const isPressed = useRef(false)
  const containerRef = useRef<HTMLButtonElement>(null)
  const [query, setQuery] = useState("")
  const sanitizedQuery = query.toLowerCase()
  const [minWidth] = useSize(containerRef)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const { isOpen, open, close, setIsOpen } = useIsOpen()

  const selectedValue = useMemo(
    () =>
      options.find(o => matcher(o, value)) ??
      optionsSupported?.find(o => matcher(o, value)),
    [options, optionsSupported, value, matcher],
  )

  const isInsideDropdown = (relatedTarget: EventTarget | null) => {
    return isInsideElement(
      dropdownRef.current?.closest("[data-tippy-root]") ?? null,
      relatedTarget,
    )
  }

  useKeyPressEvent("Escape", isOpen ? close : undefined)
  useClickAway(containerRef, event => {
    if (!isInsideDropdown(event.target)) {
      close()
    }
  })

  const shownOptions = useMemo(() => {
    const shouldNotFilter = readOnly || variant === "item"
    const results = shouldNotFilter
      ? options
      : options.filter(o => searchFilter(o, sanitizedQuery))

    return excludeSelectedOption
      ? results.filter(option => !matcher(option, value))
      : results
  }, [
    sanitizedQuery,
    options,
    excludeSelectedOption,
    value,
    variant,
    readOnly,
    searchFilter,
    matcher,
  ])

  useEffect(() => {
    onOpenChange?.(isOpen)
  }, [isOpen, onOpenChange])

  useEffect(() => {
    setQuery(selectedValue?.label ?? value ?? "")
  }, [selectedValue, value])

  useEffect(() => {
    if (hideOnScroll && isOpen) {
      window.addEventListener("scroll", close)
    }
    return () => {
      if (hideOnScroll && isOpen) {
        window.removeEventListener("scroll", close)
      }
    }
  }, [hideOnScroll, close, isOpen])

  const renderDropdownItem: RenderItem<Option> = props => {
    const { Item, item } = props

    const isActive = matcher(item, value)

    const onBlur = (event: { relatedTarget: EventTarget | null }) => {
      if (!isInsideDropdown(event.relatedTarget)) {
        close()
      }
    }

    const onClick = () => {
      onSelect(item)
      if (item.value === value && item.label !== query) {
        setQuery(item.label)
      }
      close()
    }

    if (renderItem) {
      return renderItem({ onBlur, onClick, ...props })
    }

    return (
      <Item
        disabled={item.disabled}
        key={item.key ?? item.value}
        onBlur={onBlur}
        onClick={onClick}
      >
        <Item.Content>
          <Item.Title className="flex w-full items-center justify-between text-md">
            <div>
              {item.startEnhancer ? <div>{item.startEnhancer}</div> : null}
              {item.label}
            </div>
            {isActive ? <CheckCircleFilled size={18} /> : null}
          </Item.Title>
          {item.description ? (
            <Item.Description>{item.description}</Item.Description>
          ) : null}
        </Item.Content>
      </Item>
    )
  }

  const renderDropdownProps = (): DropdownProps<Option> => {
    if (shownOptions.length === 0 && !isLoading) {
      return {
        content: function NoResults() {
          return (
            <div className="flex flex-col items-center justify-center p-8">
              {emptyText || "No results"}
            </div>
          )
        },
      }
    }

    return {
      content: function Results({ List, Item, close }) {
        return (
          <List
            className={classNames(
              twDataIdSelector(Item, "[&_[data-id=Item]]:rounded-lg"),
              "overflow-auto bg-transparent p-3",
            )}
            ref={dropdownRef}
            showBorder={false}
            style={{ maxHeight }}
          >
            {shownOptions.map(item =>
              renderDropdownItem({ Item, item, close }),
            )}

            {isLoading ? <Loader /> : null}
          </List>
        )
      },
    }
  }

  const showMoreIcon = (
    <div className="flex flex-col justify-center">
      <ShowMoreIcon
        isActive={isOpen}
        {...overrides?.ShowMoreIcon?.props}
        className={classNames("ml-2", overrides?.ShowMoreIcon?.props.className)}
      />
    </div>
  )

  const commonProps = {
    "aria-label": ariaLabel,
    "aria-haspopup": true,
    onClick: () => {
      setIsOpen(prev => !prev)
    },
    onFocus: () => {
      if (!isPressed.current) {
        open()
      }
      isPressed.current = false
    },
    onMouseDown: () => {
      isPressed.current = true
    },
  }

  const renderSearchVariant = () => {
    return (
      <Input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classNames(
          "font-inter text-primary",
          preventInputZoomStyles,
          {
            "border-0 hover:border-0 focus:border-0": !bordered,
            "cursor-pointer": readOnly,
          },
          className,
        )}
        clearOnEscape
        clearable={clearable}
        disabled={disabled}
        endEnhancer={
          isLoading ? (
            <div className="ml-2 flex flex-col justify-center">
              <Loader size="small" />
            </div>
          ) : (
            endEnhancer ?? showMoreIcon
          )
        }
        id={id}
        name={name}
        onBlur={event => {
          if (isPressed.current) {
            return
          }
          // Dont clear "invalid" option if one was just selected
          if (isInsideDropdown(event.relatedTarget)) {
            return
          }

          if (!options.some(o => o.label === query)) {
            if (value) {
              onSelect(undefined)
            } else {
              setQuery("")
            }
          }

          // Close if focus went outside and not if e.g. close icon got focus
          if (!isInsideRef(containerRef, event.relatedTarget)) {
            close()
          }
        }}
        onChange={event => {
          setQuery(event.currentTarget.value)
          if (!event.currentTarget.value && value) {
            onSelect(undefined)
          }
          onChange?.(event.currentTarget.value)
          open()
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={containerRef as React.RefObject<HTMLInputElement>}
        startEnhancer={
          startEnhancer ? (
            <div className="flex flex-col justify-center">{startEnhancer}</div>
          ) : undefined
        }
        style={style}
        tabIndex={tabIndex}
        value={query}
        {...commonProps}
        {...overrides?.Input?.props}
      />
    )
  }

  const renderItemVariant = () => {
    return (
      <Item
        disabled={disabled}
        ref={containerRef}
        {...commonProps}
        {...overrides?.ContentItem?.props}
        className={classNames(
          "duration-[250ms] inline-flex items-center px-3 pb-4 pt-3",
          "h-12 w-full rounded-medium border-0 bg-component-gray-1 p-3 py-1 pr-2 !shadow-none hover:bg-component-gray-2 active:bg-component-gray-3",
          {
            "border-0 hover:border-0 focus:border-0": !bordered,
          },
          overrides?.ContentItem?.props.className,
          className,
        )}
        onBlur={(event: React.FocusEvent<HTMLElement>) => {
          if (!isInsideDropdown(event.relatedTarget)) {
            close()
          }
        }}
      >
        {startEnhancer ? (
          <div className="flex flex-col justify-center">{startEnhancer}</div>
        ) : null}
        <input placeholder={placeholder} type="hidden" value={query} />
        <Item.Content className="mr-0" {...overrides?.ContentLabel?.props}>
          <Item.Title
            className="text-md"
            {...overrides?.ContentLabelTitle?.props}
          >
            {overrides?.ContentLabelTitle?.props.children ??
              selectedValue?.label ??
              value ??
              placeholder}
          </Item.Title>
          {selectedValue?.description ? (
            <Item.Description>{selectedValue.description}</Item.Description>
          ) : null}
        </Item.Content>
        <Item.Side className="max-w-none">
          {endEnhancer ?? showMoreIcon}
        </Item.Side>
      </Item>
    )
  }

  const renderContent = () => {
    switch (variant) {
      case "search":
        return renderSearchVariant()
      case "item":
        return renderItemVariant()
      default:
        throw new UnreachableCaseError(variant)
    }
  }

  return (
    <Dropdown
      disabled={disabled}
      visible={isOpen}
      {...renderDropdownProps()}
      maxHeight={maxHeight}
      minWidth={minWidth}
      offset={[0, 8]}
      {...overrides?.Dropdown?.props}
    >
      {renderContent()}
    </Dropdown>
  )
}
