import { createContext } from "use-context-selector"
import { asyncNoop, noop } from "@/lib/helpers/noop"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { Currency, CurrencyId } from "./currency"

export type WalletBalance = {
  currency: Currency
  quantity: string
}

type UnsubscribeFn = () => void

export type WalletBalanceContextType = {
  subscribe: (chain?: ChainIdentifier, symbol?: string) => UnsubscribeFn
  refresh: () => Promise<void>
  balances: Partial<Record<CurrencyId, WalletBalance>>
  readyChains: Partial<Record<ChainIdentifier | "all", boolean>>
  readyCurrencies: Partial<Record<CurrencyId, boolean>>
}

export const WalletBalanceContext = createContext<WalletBalanceContextType>({
  subscribe: () => noop,
  refresh: asyncNoop,
  balances: {},
  readyChains: {},
  readyCurrencies: {},
})
