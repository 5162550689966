import { useCallback, useState } from "react"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import type { useFetchWalletBalancesQuery } from "@/lib/graphql/__generated__/useFetchWalletBalancesQuery.graphql"
import type { WalletBalance } from "../context"
import type { CurrencyInput } from "../currency"

export function useFetchWalletBalances(): {
  fetch: (
    address: string,
    currencies: CurrencyInput[],
  ) => Promise<WalletBalance[]>
  ready: boolean
} {
  const environment = useRelayEnvironment()

  const [ready, setReady] = useState(false)

  const fetch = useCallback(
    async (
      address: string,
      currencies: CurrencyInput[],
    ): Promise<WalletBalance[]> => {
      const balances = await Promise.all(
        currencies.map(async currency => {
          const response = await fetchQuery<useFetchWalletBalancesQuery>(
            environment,
            graphql`
              query useFetchWalletBalancesQuery(
                $address: AddressScalar!
                $chain: ChainScalar!
                $symbol: String!
              ) {
                wallet(address: $address) {
                  fundsOf(chain: $chain, symbol: $symbol) {
                    symbol
                    chain
                    quantity
                    usdPrice
                    image
                    name
                  }
                }
              }
            `,
            { address, chain: currency.chain, symbol: currency.symbol },
          ).toPromise()
          if (!response) {
            throw new Error("Wallet balance fetch returned no response")
          }
          return {
            currency: {
              symbol: response.wallet.fundsOf.symbol,
              chain: response.wallet.fundsOf.chain,
              usdPrice: response.wallet.fundsOf.usdPrice ?? undefined,
              imageUrl: response.wallet.fundsOf.image ?? undefined,
              name: response.wallet.fundsOf.name ?? undefined,
            },
            quantity: response.wallet.fundsOf.quantity,
          }
        }),
      )
      setReady(true)
      return balances
    },
    [environment],
  )

  return { fetch, ready }
}
