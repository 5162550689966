import { useMemo } from "react"
import { getEquallySizedChainLogo } from "@/lib/helpers/chainUtils"
import { useChains } from "./useChains"

export const useChainOptions = () => {
  const { chains, getChainName, isChainFiltersEnabled } = useChains()
  return useMemo(
    () => [
      ...chains
        .filter(chain => isChainFiltersEnabled(chain))
        .map(chain => ({
          label: getChainName(chain),
          value: chain,
          Logo: getEquallySizedChainLogo(chain),
        })),
    ],
    [chains, isChainFiltersEnabled, getChainName],
  )
}

export type ChainOption = ReturnType<typeof useChainOptions>[number]
