import React, { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { createContext } from "use-context-selector"
import { WALLET_NAME } from "@/constants/wallet"
import { useIsOSWalletEnabled } from "@/hooks/useIsOSWalletEnabled"
import { useVesselActions } from "@/providers/Vessel00/useVesselActions"
import { useVessel } from "../Vessel00/VesselProvider.react"
import { WalletLayout } from "./WalletLayout.react"

const DEFAULT_WALLET_LAYOUT_VALUE = {
  Header: () => null,
}

const DEFAULT_WALLET_LAYOUT_CONTEXT: WalletLayoutContextType = {
  ...DEFAULT_WALLET_LAYOUT_VALUE,
}

type WalletLayoutContextType = {
  Header: ({ children }: { children: React.ReactNode }) => JSX.Element | null
}

export const WalletLayoutContext = createContext<WalletLayoutContextType>(
  DEFAULT_WALLET_LAYOUT_CONTEXT,
)

type WalletProviderProps = {
  children: React.ReactNode
  disabled?: boolean
  hideHeader?: boolean
}

export function WalletLayoutProvider({
  children,
  disabled,
  hideHeader,
}: WalletProviderProps) {
  const [headerEl, setHeaderEl] = useState<HTMLDivElement | null>(null)
  const isOSWalletEnabled = useIsOSWalletEnabled()
  const { installWallet } = useVesselActions()
  const { isConnected } = useVessel()

  const Header = useCallback(
    ({ children: c }: { children: React.ReactNode }) => {
      if (!headerEl) {
        return null
      }
      return createPortal(c, headerEl)
    },
    [headerEl],
  )

  useEffect(() => {
    // Ensures OpenSea wallet is installed and can be switched to even if the
    // user is not logged in, e.g. using a wallet extension.
    if (isOSWalletEnabled && isConnected) {
      void installWallet(WALLET_NAME.OpenSeaWallet)
    }
  }, [installWallet, isOSWalletEnabled, isConnected])

  if (!isOSWalletEnabled && process.env.IS_STORYBOOK !== "true") {
    return null
  }

  if (disabled) {
    return <>{children}</>
  }

  return (
    <WalletLayoutContext.Provider value={{ Header }}>
      <WalletLayout hideHeader={hideHeader} setHeaderEl={setHeaderEl}>
        {children}
      </WalletLayout>
    </WalletLayoutContext.Provider>
  )
}
