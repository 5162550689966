import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import type { IconProps } from "@opensea/ui-kit/icons"
import { ExpandMore } from "@opensea/ui-kit/icons"
import React from "react"

export type ShowMoreIconProps = Omit<IconProps, "value" | "color"> & {
  isActive?: boolean
} & Pick<JSX.IntrinsicElements["i"], "aria-label">

export function ShowMoreIcon({ isActive = false, ...rest }: ShowMoreIconProps) {
  const t = useTranslate("common")

  return (
    <ExpandMore
      {...rest}
      aria-label={
        isActive
          ? t("showMoreIcon.showLess", "Show less")
          : t("showMoreIcon.showMore", "Show more")
      }
      className={classNames(
        "duration-[250ms] text-primary transition-[transform,background-color] ease-in-out",
        {
          "rotate-180": isActive,
        },
        rest.className,
      )}
    />
  )
}
