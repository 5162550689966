import { useCallback } from "react"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { useFetchAllWalletBalancesQuery } from "@/lib/graphql/__generated__/useFetchAllWalletBalancesQuery.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import type { WalletBalance } from "../context"

// backend is truncating/rounding up some balances, so we truncate here to stay under actual balance
function truncateBalance(balance: string): string {
  const decimalIndex = balance.indexOf(".")

  if (decimalIndex === -1) {
    // No decimal point, return the original string
    return balance
  }

  const maxDecimalLength = 15
  let endIndex = decimalIndex + maxDecimalLength + 1 // +1 to include the decimal point itself

  // Ensure we do not exceed the string's length
  if (endIndex > balance.length) {
    endIndex = balance.length
  }

  return balance.substring(0, endIndex)
}

export function useFetchAllWalletBalances(): (
  address: string,
  chain?: ChainIdentifier,
) => Promise<WalletBalance[]> {
  const environment = useRelayEnvironment()

  const fetch = useCallback(
    async (
      address: string,
      chain?: ChainIdentifier,
    ): Promise<WalletBalance[]> => {
      const response = await fetchQuery<useFetchAllWalletBalancesQuery>(
        environment,
        graphql`
          query useFetchAllWalletBalancesQuery(
            $address: AddressScalar!
            $chain: ChainScalar
          ) {
            wallet(address: $address) {
              funds(chain: $chain) {
                symbol
                chain
                quantity
                usdPrice
                image
                name
              }
            }
          }
        `,
        { address, chain },
      ).toPromise()
      const balances = response?.wallet.funds.map(f => ({
        currency: {
          symbol: f.symbol,
          chain: f.chain,
          usdPrice: f.usdPrice ?? undefined,
          imageUrl: f.image ?? undefined,
          name: f.name ?? undefined,
        },
        quantity: truncateBalance(bn(f.quantity).toString()),
      }))
      return balances ?? []
    },
    [environment],
  )

  return fetch
}
